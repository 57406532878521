<template>
    <cluji-card title="Préférences mail" subtitle="Désactivez les types de mail que vous ne souhaitez pas recevoir">
        <p v-for="forbiddenMail in forbiddenMailTypes"
           :key="'mail_type_'+forbiddenMail.type"
           v-if="forbiddenMail.display ? forbiddenMail.display() : true">
            <toggle-button v-model="forbiddenMail.allowed" class="m-1" :sync="true"
                           @change="updateForbiddenMail(forbiddenMail)"/>
            <span class="ml-2">{{ forbiddenMail.label }}</span>
        </p>
    </cluji-card>
</template>

<script>
    const ClujiCard = () => import('@/components/ClujiCard');

    import {ToggleButton} from 'vue-js-toggle-button';

    import alert                from '@/util/alert';
    import {apiPath}            from '@/util/http';
    import {isCluji, isAnyAsso} from '@/util/auth';

    export default {
        name: "MailPreferencesCard",
        components: {ClujiCard, ToggleButton},
        data: () => ({
            forbiddenMailTypes: [
                {
                    allowed: true,
                    type: 1,
                    label: "Validation d'une réservation du local",
                    display: isCluji
                },
                {
                    allowed: true,
                    type: 2,
                    label: "Validation d'un événement que vous organisez",
                    display: isCluji
                },
                {
                    allowed: true,
                    type: 3,
                    label: "Validation d'un emprunt",
                    display: isAnyAsso
                },
                {
                    allowed: true,
                    type: 4,
                    label: "Commentaire sur un événement que vous suivez"
                }
            ]
        }),
        methods: {
            loadData() {
                alert.loading();
                this.axios.get(apiPath('get_forbidden_mail'))
                    .then(response => this.forbiddenMailTypes = this.forbiddenMailTypes.map(
                        forbiddenMail => ({
                            ...forbiddenMail,
                            allowed: !response.data.find(item => item.type === forbiddenMail.type)
                        })
                    ))
                    .catch(() => this.$toaster.error("Impossible de récupérer vos préférences de mail"))
                    .finally(alert.stopLoading);
            },
            updateForbiddenMail(data) {
                alert.loading();

                this.axios.post(apiPath('set_forbidden_mail'), {type: data.type, allowed: data.allowed})
                    .then(() => this.$toaster.success('Préférences enregistrées'))
                    .catch(() => this.$toaster.error("Impossible d'enregistrer vos préférences de mail"))
                    .finally(() => this.loadData());
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
